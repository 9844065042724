export default [
  // {
  //   name: 'FAQ',
  //   to: 'FAQ'
  // },
  // {
  //   name: 'Support Tickets',
  //   to: 'SupportTickets'
  // },
  {
    name: 'File Transfer',
    to: 'FileTransfer'
  }
]
