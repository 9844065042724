<template>
  <div />
</template>

<script>
import navigation from './navigation'

export default {
  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'FAQ',
      back: { name: 'Support' }
    })

    this.$store.commit('page/setTabs', navigation)
  }
}
</script>
